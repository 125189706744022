.list {
  overflow: auto;
  height: 500px;
  padding: 16px;

  .item {
    & + .item {
      margin-top: 12px;
    }
  }
}
