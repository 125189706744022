@import '~@kontentino/ui/dist/scss/colors';

.blur {
  filter: blur(4px);
}

code {
  color: $secondary;
}

.badge-absolute-top-right {
  position: absolute;
  top: -8px;
  right: -8px;
}

.line-clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.react-loading-skeleton.react-loading-skeleton {
  z-index: auto;
}
