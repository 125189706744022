.main {
  flex: 1 0;
  overflow: auto;
  padding: 24px;

  .info {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0 64px 64px 0;

    .sharedBy {
      font-size: 20px;
      font-weight: 600;
    }
  }

  .icon {
    margin: 10px;
    @apply tw-text-grayscale-190;
  }

  .text {
    font-size: 12px;
    @apply tw-text-grayscale-180;
  }

  .closeIcon {
    margin-left: auto;
  }

  .wrapper {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: auto;

    .column {
      display: flex;
      flex-direction: column;
      flex-basis: 50%;
      justify-content: flex-start;
      align-items: center;
      max-width: 50vw;

      .comments {
        display: flex;
        width: 100%;
        justify-content: flex-start;
        align-items: flex-start;
      }
    }
  }
}

.footer {
  padding: 16px 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 0 1;
  @apply tw-bg-white tw-shadow-sm;

  .internalBtn {
    justify-content: flex-end;
    margin: 0 4px;
  }
}
