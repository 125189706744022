.title {
  display: flex;
  align-items: center;

  svg {
    margin-left: 4px;
  }
}

.button.button {
  height: unset;
  padding: 6px 8px;
}
