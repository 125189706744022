@import '~@kontentino/ui/dist/scss/colors';

.ProseMirror {
  padding: 12px;
  font-size: 14px;

  /* Placeholder (at the top) */
  p.is-editor-empty:first-child::before {
    @apply tw-text-grayscale-50;
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
  }

  a:hover {
    color: $primary;
  }
}

.ProseMirror-focused {
  outline: none;
}

.tippy-box.tippy-box[data-theme='suggestion-list'] {
  background-color: transparent;
  border: none;
  padding: 0;

  .tippy-content {
    padding: 0;
  }
}
