.headerWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    white-space: nowrap;
  }

  .stepper {
    width: 100%;
  }
}
